// Import Packages
import * as React from "react"
import { Link } from "gatsby"

// Import Templates
import { PageTitle, Header, Footer }from "../templates/Components"

// Import Assets
import "../css/style.css"

export default function PracticesPage () {
  return (
    <div>
      <PageTitle pageTitle="Practices | "/>
      <Header/>
      <div id="contents">
        <div className="clearfix">
          <div className="sidebar">
            <div>
              <h2>Practices</h2>
              <ul>
                <li>
                  <Link to="#criminalLaw">Criminal Defense</Link>
                </li>
                <li>
                  <Link to="#personalInjury">Personal Injury</Link>
                </li>
                <li>
                  <Link to="#familyLaw">Family Law</Link>
                </li>
                <li>
                  <Link to="#estatePlanning">Estate Planning</Link>
                </li>
                
              </ul>
            </div>
            <div>
              {/* <!-- <h2>Client Testimonials</h2>
              <p>
                &ldquo;The best family lawyers in the city so far. Me and my ex-wife didn’t have any<br> problems settling the terms and agreement. Everything went so smoothly. We’re both very happy.&rdquo; <span>- Jared Greene</span>
              </p> --> */}
            </div>
          </div>
          <div className="main">
            <h1>Practices</h1>
            <p>
              With experience in a wide variety of practice areas, Mr. Rizzi can help you with your legal issues ranging from defending your criminal charges to helping you through a divorce. Give us a call at (520) 591-7919 to see how we can help you.
            </p>
            <h2 id="criminalLaw">Criminal Defense</h2>
            <p>
              Prior to opening his own law practice, Mr. Rizzi served the community as a prosecutor for just short of 5 years. During that time, he handled hundreds of criminal cases as a State’s attorney. Now Mr. Rizzi brings that experience and knowledge to help defend you. Just as Mr. Rizzi understands how prosecutors build a case, he knows how to tear one apart. With Mr. Rizzi in your corner, you have someone with years of negotiation skills and trial experience… But most importantly, you have someone who cares about you and your case.
            </p>
            <h2 id="personalInjury">Personal Injury</h2>
            <p>
              When looking for a Personal Injury Attorney you want someone you know can prove your case at trial. Thanks to his experience as a prosecutor, Mr. Rizzi not only has a strong grasp on the evidentiary rules, but also understands how to effectively present evidence to prove a case. Using the same strategies and techniques he once used to prove cases Beyond a Reasonable Doubt in the criminal arena, Mr. Rizzi is here to help you prove your personal injury case in the civil arena.
            </p>
            <h2 id="familyLaw">Family Law</h2>
            <p>
              Divorce, paternity, child custody, and guardianship can be scary to navigate on your own. With Mr. Rizzi assisting you, you can rest assured that you will have representation that exceeds expectations. Not only does Mr. Rizzi bring a careful eye to each and every case, but he dedicates himself to every one of his clients. You will never feel alone when you have Mr. Rizzi on your team.
            </p>
            <h2 id="estatePlanning">Estate Planning</h2>
            <p>
              When failing health, incapacity, or death plagues your loved ones, Mr. Rizzi can offer some comfort. With Mr. Rizzi assisting you plan the security and administration of your assets the way you want, you can leave the legalities to us and focus on the things that are truly important.
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}